import React from 'react'
import './Header.css'
import TopBar from './TopBar'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import AnnouncementIcon from '@mui/icons-material/Campaign';
import Marquee from 'react-fast-marquee'


const Header = () => {

  return (
    <>
      <TopBar />
      <header className='justify-content-space-around' position="fixed">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" >
          <Container>
            <Navbar.Brand href="/">
              <img src="/Assets/images/logos/183x66 - white.png" alt='superjcybs logo' title='superjcybs logo' loading='lazy' className="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="offcanvasNavbar-expand-false" placement="end" />
            <Navbar.Offcanvas id="offcanvasNavbar-expand-false" aria-labelledby="offcanvasNavbarLabel-expand-false" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id="offcanvasNavbarLabel-expand-false">
                  SUPERJCYBS 
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto" >
                  <Nav.Link href="/features">Features </Nav.Link>
                  <Nav.Link href="https://tstelecom.superjcybs.com">Sell Data</Nav.Link>
                  <Nav.Link href="/courses">Courses</Nav.Link>
                  <Nav.Link href="/tsbf-ghana">TSBF GH</Nav.Link>
                  <Nav.Link href="/earningways">Earning Ways</Nav.Link>
                </Nav>

                <Nav>
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link eventKey={2} href="/#about">About</Nav.Link>
                  <Nav.Link eventKey={3} href="/#service">Services</Nav.Link>
                  <Nav.Link eventKey={4} href="/#portfolio">Portfolio</Nav.Link>
                  <Nav.Link eventKey={5} href="/blog">Blog</Nav.Link>
                  <Nav.Link eventKey={6} href="/#team">Team</Nav.Link>
                  <Nav.Link eventKey={7} href="/#contact">Contact</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
        <Marquee
          gradient={false}
          pauseOnHover={true}
          style={{ Direction: "right", width: "100%", height: "50", scrollAmount: "1055", backgroundColor: "Red", color: '#fff', fontWeight: 'bold' }} >
          --------------<AnnouncementIcon /> HOT DEALS: {'    =>'}  NEW YEAR PROMO - 30% DISCOUNT on all orders made from 1st January, 2024 to 25th February, 2024
          -------- PLACE YOUR ORDER TODAY!----- We take care of your   WIFI Hotspot Services for Homes & Offices, Mobile Money Services, Airtime, Network Marketing, Website & Database Programming, Graphic Designing, Multi TV installation & Configuration, Computer Installation & Configuration, Biogas Technology, Bio-Digestor for your toilet and 3D Epoxy interior and exterior Designing 
          </Marquee>
      </header>
    </>
  )
}

export default Header