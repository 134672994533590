import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TelegramIcon from '@mui/icons-material/Telegram';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TwitterIcon from '@mui/icons-material/Twitter';

import './Footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>

      <footer id="footer" >
        {/* <div class="overlay-mf"></div> */}
        <a href="https://bit.ly/superjcybsonppl" className='d-flex justify-content-center'>
          <img src="http://cashsuperstar.com/ppl/images/makemoneynow728x90.jpg" style={{ width: '80vw' }} alt="Want To Increase Your Online Income? Then Get This System..." />
        </a>
        <div className="footer-newsletter" >

          <div className="container" data-aos="fade-up">

            <div className="row justify-content-center">
              <div className="col-lg-6">
                <h4>Join Our Newsletter</h4>
                <p>We'd be so glad to share with you some of our amazing updates as and when they troop in.</p>
                <form method="POST" action="https://formspree.io/f/myyoonyo" target='blank' >
                  <input type="hidden" name="homepage_superjcybs.com" value="newsletter_subscription" />
                  <div data-netlify-recaptcha="true"></div>
                  <input type="email" name="email" placeholder='Enter your email...' /><input type="submit" defaultValue="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top" >
          {/* <div class="overlay-mf"></div> */}
          <div className="container">

            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay={100}>
                <h4>Useful Links</h4>
                <ul>
                  <li><i><ChevronRightIcon /></i> <Link to="/">Home</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="#about">About us</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/#service">Services</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/portfolio">Our Works</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/#team">Our Team</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/terms">Terms of service</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/privacy">Privacy policy</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/sitemap.txt">Sitemap</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay={200}>
                <h4>Other Resources</h4>
                <ul>
                  <li><i><ChevronRightIcon /></i> <Link to="/blog">Get Informed Blog</Link></li>
                  <li><i><ChevronRightIcon /></i> <a href="/dunamistravels" target="blank">Dunamis Travels</a></li>
                  <li><i><ChevronRightIcon /></i> <a href="https://supamartghana.superjcybs.com" target="blank">Supamart Ghana</a></li>
                  <li><i><ChevronRightIcon /></i> <Link to="https://tstelecom.superjcybs.com/" target="blank">TS Telecom</Link></li>
                  <li><i><ChevronRightIcon /></i> <a href="https://globalnewstyme.blogspot.com" target="blank">Global News Time</a></li>
                  <li><i><ChevronRightIcon /></i> <a href="/earningways" target="blank">Earning Ways</a></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/tsbf-ghana" target="blank">TSBF GHANA</Link></li>
                  <li><i><ChevronRightIcon /></i> <a href='https://choptime.superjcybs.com/' target="blank" >Choptime Food App</a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay={300}>
                <h4>Our Services</h4>
                <ul>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#graphicsdesign">Graphics & Web Design</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#web-development">Web Development</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#project-management">Project Management</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#digital-marketing">Digital Marketing</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#mobile-app-development">Mobile App Development</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#computer-training">Computer Training</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#hrmgt">Human Resource & Supply Chain</Link></li>
                  <li><i><ChevronRightIcon /></i> <Link to="/services/#bulk-messaging">Bulk Messaging</Link></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay={400}>
                <h4>Our Social Networks</h4>
                <p>Don't be far away from us via social platforms, follow us on;</p>
                <div className="social-links mt-3">
                  <a href="https://www.youtube.com/channel/UCnYL6e5pYht_mWKlEE7NTLw?sub_confirmation=1" className="twitter" target="blank"><i><YouTubeIcon style={{ color: "red" }} /></i></a>
                  <a href="https://www.facebook.com/superjcybs" className="facebook" target="blank"><i> <FacebookIcon color="primary" /></i></a>
                  <a href="https://www.instagram.com/superjcybs" className="instagram" target="blank"><i><InstagramIcon style={{ color: "#8334b9" }} /></i></a>
                  <a href="https://t.me/superjcybs" className="google-plus" target="blank"><i><TelegramIcon style={{ color: "#0088cc" }} /></i></a>
                  <a href="https://www.linkedin.com/company/super-j-cy-business-and-services" className="linkedin" target="blank"><i><LinkedInIcon color="primary" /></i></a>
                  <a href="https://www.twitter.com/superjcybs" className="twitter" target="blank"><i><TwitterIcon style={{ color: "black" }} /></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container vdown py-2">
          <div className="copyright">
            © Copyright 2020 <strong><span>Super 'J' Cy-Business And Services</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Developed by <Link to="/#about" target="blank">SUPERJCYBS (+233247792110)</Link>
          </div>
        </div>
      </footer>

    </div>
  )
}

export default Footer